<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Objet
          </th>
          <th class="text-left">
            Prix unitaire
          </th>
          <th class="text-left">
            Unités
          </th>
          <th
            v-if="tva"
            class="text-left"
          >
            TVA
          </th>
          <th class="text-center">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
        >
          <td class="mw-150">
            <b>{{ item.title }}</b> <br>
            <span
              class="linepre"
              v-html="item.description"
            ></span>
          </td>
          <td>{{ Number(item.cost).toLocaleString('fr-FR') }} €</td>
          <td>{{ item.units }}</td>
          <td v-if="tva">
            {{ lineTva(index).toLocaleString('fr-FR') }}  €
          </td>
          <td
            v-if="lineDiscount(index) !== 0"
            class="text-center"
          >
            <small><s>{{ lineSubTotal(index).toLocaleString('fr-FR') }} €</s></small><br>
            <small>- {{ lineDiscount(index).toLocaleString('fr-FR') }} € </small><br>
            <b> {{ lineTotal(index).toLocaleString('fr-FR') }} €</b>
          </td>
          <td
            v-else
            class="text-center"
          >
            {{ lineSubTotal(index).toLocaleString('fr-FR') }} €
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [{}],
    },
    tva: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    lineSubTotal(index) {
      const { units } = this.items[index]
      const { cost } = this.items[index]
      const total = units * cost

      return Number(total)
    },
    lineDiscount(index) {
      const { units } = this.items[index]
      const { cost } = this.items[index]
      const { discount } = this.items[index]

      const total = units * cost
      const discountCalculated = total * (discount / 100)

      return Number(discountCalculated)
    },
    lineTotal(index) {
      const { units } = this.items[index]
      const { cost } = this.items[index]
      const discount = this.lineDiscount(index)
      const total = (units * cost) - discount

      return Number(total)
    },
    lineTva(index) {
      const lineTotal = this.lineTotal(index)
      const total = lineTotal * 0.2

      return Number(total)
    },
  },

}
</script>

<style>
.mw-150{
    max-width: 250px;
}
.linepre{
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

</style>
