<template>
  <div id="companyRibTable">
    <table>
      <tbody>
        <tr>
          <td class="pe-6">
            Banque :
          </td>
          <td>{{ getCompanyRib.bank }}</td>
        </tr>
        <tr>
          <td class="pe-6">
            BIC :
          </td>
          <td>{{ getCompanyRib.bic }}</td>
        </tr>
        <tr>
          <td class="pe-6">
            IBAN :
          </td>
          <td>{{ getCompanyRib.iban }}</td>
        </tr>
        <tr>
          <td class="pe-6">
            RIB :
          </td>
          <td>{{ getCompanyRib.rib }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getCompanyRib() {
      return (this.company ? this.company.rib : this.$store.state.company.rib)
    },
  },
}
</script>

<style>

</style>
