<template>
  <div class="v-card-text d-flex justify-space-between ">
    <v-spacer></v-spacer>
    <div>
      <table class="w-100">
        <tr>
          <td class="pe-16">
            Sous-total :
          </td>
          <td class="text-end">
            {{ subTotal().toLocaleString('fr-FR') }} €
          </td>
        </tr>
        <tr v-if="totalDiscount() > 0">
          <td class="pe-16">
            Réduction :
          </td>
          <td class="text-end">
            {{ totalDiscount().toLocaleString('fr-FR') }}€
          </td>
        </tr>
      </table>
      <div v-if="tva">
        <v-divider class="mt-4 mb-4"></v-divider>
        <table class="w-100">
          <tr>
            <td
              class="pe-16"
            >
              Total H.T. :
            </td>
            <td class="text-end">
              {{ (subTotal()-totalDiscount()).toLocaleString('fr-FR') }}€
            </td>
          </tr>
          <tr>
            <td
              class="pe-16"
            >
              T.V.A (20%) :
            </td>
            <td class="text-end">
              {{ totalTva().toLocaleString('fr-FR') }}€
            </td>
          </tr>
        </table>
      </div>
      <v-divider class="mt-4 mb-4"></v-divider>
      <table class="w-100">
        <tr>
          <td class="pe-16 font-weight-bold">
            Total :
          </td>
          <td class="text-end font-weight-bold">
            {{ total().toLocaleString('fr-FR') }} €
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    tva: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    subTotal() {
      const total = this.items.reduce((accumulator, item) => accumulator + (item.cost * item.units), 0)

      return Number(total)
    },
    totalDiscount() {
      const total = this.items.reduce((accumulator, item) => {
        if (item.discountEnabled) {
          const { units } = item
          const { cost } = item
          const { discount } = item
          const totalItem = units * cost
          const discountCalculated = totalItem * (discount / 100)

          return accumulator + discountCalculated
        }

        return accumulator
      }, 0)

      return Number(total)
    },
    totalTva() {
      if (this.tva) {
        let subTotal = this.subTotal()
        if (this.totalDiscount() > 0) {
          subTotal -= this.totalDiscount()
        }
        const total = subTotal * 0.2

        return Number(total)
      }

      return false
    },
    total() {
      const subTotal = this.subTotal()
      const totalDiscount = this.totalDiscount()
      const totalTva = () => {
        if (this.tva) {
          return this.totalTva()
        }

        return 0
      }
      let result = subTotal
      if (totalDiscount > 0) {
        result -= totalDiscount
      }
      if (totalTva() > 0) {
        result += totalTva()
      }

      return Number(result)
    },
  },

}
</script>

<style>

</style>
