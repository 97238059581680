<template>
  <div
    v-if="getCompany"
    id="companyInfoBloc"
  >
    <div class="d-flex align-center mb-6">
      <div>
        <img
          src=""
          alt=""
        >
      </div>
      <h4>{{ getCompany.name.toUpperCase() }}</h4>
    </div>
    <p
      v-if="getCompany.address.line1"
      class="mb-0"
    >
      {{ getCompany.address.line1 }}
    </p>
    <p
      v-if="getCompany.address.line2"
      class="mb-0"
    >
      {{ getCompany.address.line2 }}
    </p>
    <p
      v-if="getCompany.address.city"
      class="mb-0"
    >
      {{ getCompany.address.city }}
    </p>
    <p
      v-if="getCompany.address.postcode"
      class="mb-0"
    >
      {{ getCompany.address.postcode }}
    </p>
    <p
      v-if="getCompany.phone"
      class="mb-0"
    >
      {{ getCompany.phone }}
    </p>
    <p class="mb-0">
      SIRET {{ getCompany.siret }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getCompany() {
      return (this.company ? this.company : this.$store.state.company)
    },
  },
}
</script>

<style>

</style>
