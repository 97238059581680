<template>
  <div v-if="person">
    <p
      v-if="person.firstName && person.lastName"
      class="mb-0"
    >
      {{ person.firstName }}  {{ person.lastName }}
    </p>
    <p
      v-if="person.companyName"
      class="mb-0"
    >
      {{ person.companyName }}
    </p>
    <p
      v-if="person.name"
      class="mb-0"
    >
      {{ person.name }}
    </p>
    <p
      v-if="person.address.line1"
      class="mb-0"
    >
      {{ person.address.line1 }}
    </p>
    <p
      v-if="person.address.line2"
      class="mb-0"
    >
      {{ person.address.line2 }}
    </p>

    <p
      v-if="person.address.postcode && person.address.city"
      class="mb-0"
    >
      {{ person.address.postcode }} {{ person.address.city }}
    </p>
    <p
      v-if="person.phone"
      class="mb-0"
    >
      {{ person.phone }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      default: null,
    },
  },

}
</script>

<style>

</style>
