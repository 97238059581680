<template>
  <div
    class="invoiceWrapper"
  >
    <v-card
      v-if="!loading"
      class="a4"
      elevation="4"
    >
      <!-- FIRST BLOCK -->
      <v-card-text class="d-flex justify-space-between">
        <div class="mb-6">
          <company-infos-bloc
            :company="invoice.company"
          ></company-infos-bloc>
        </div>
        <div class="mb-3">
          <h6
            class="d-flex justify-space-between font-weight-medium text-xl"
          >
            <span class="me-3">Facture :</span>
            <span class="">
              {{ invoice.number }}
            </span>
          </h6>
          <p
            class="d-flex justify-space-between  justify-end"
          >
            <span class="me-3">Date de facturation :</span>
            <span class="">
              {{ new Date(invoice.invoiceDate) | date('dd/MM/yyyy') }}
            </span>
          </p>
          <p
            class="d-flex  justify-space-between justify-end"
          >
            <span class="me-3">Date d'échéance :</span>
            <span class="">
              {{ new Date(invoice.limitDate) | date('dd/MM/yyyy') }}
            </span>
          </p>
        </div>
      </v-card-text>
      <!-- END FIRST BLOCK -->
      <v-divider></v-divider>
      <!-- SECOND BLOCK -->
      <v-card-text class="d-flex justify-space-between">
        <div class="mb-6">
          <p class="text-sm font-weight-medium mb-3">
            Facturé à :
          </p>
          <person-address :person="getPerson()"></person-address>
        </div>
        <div class="mb-3">
          <p class="text-sm font-weight-medium mb-3">
            Informations de facturation :
          </p>
          <company-rib-table
            :company="invoice.company"
          ></company-rib-table>
        </div>
      </v-card-text>
      <!-- END SECOND BLOCK -->
      <v-divider></v-divider>
      <!-- THIRD BLOCK -->
      <v-card-text>
        <invoice-builder-preview
          :items="invoice.items"
          :tva="invoice.tva"
        >
        </invoice-builder-preview>
      </v-card-text>
      <!-- END THIRD BLOCK -->
      <v-divider></v-divider>
      <v-card-text>
        <invoice-builder-total-box
          :tva="invoice.tva"
          :items="invoice.items"
        ></invoice-builder-total-box>
      </v-card-text>
      <v-divider></v-divider>
      <!-- FOURTH BLOCK -->
      <v-card-text
        v-if="invoice.note"
        class="d-flex flex-column"
      >
        <p class="font-weight-semibold mb-2">
          Note :
        </p>
        <span
          class="linepre"
          v-html="invoice.note"
        >

        </span>
      </v-card-text>
      <!-- END FOURTH BLOCK -->
      <!-- FOOTER BLOCK -->
      <v-divider></v-divider>
      <v-card-text class="text-center">
        <p class="font-weight-semibold mb-2">
          Jimbo CRM ™
        </p>
      </v-card-text>
      <!-- END FOURTH BLOCK -->
    </v-card>
  </div>
</template>

<script>
import CompanyInfosBloc from '@/components/CompanyInfosBloc.vue'
import PersonAddress from '@/components/PersonAddress.vue'
import CompanyRibTable from '@/components/CompanyRibTable.vue'
import InvoiceBuilderPreview from '@/components/InvoiceBuilderPreview.vue'
import InvoiceBuilderTotalBox from '@/components/InvoiceBuilderTotalBox.vue'

export default {
  components: {
    CompanyInfosBloc,
    PersonAddress,
    CompanyRibTable,
    InvoiceBuilderPreview,
    InvoiceBuilderTotalBox,
  },
  metaInfo: {
    title: 'Invoice generation',
    titleTemplate: '%s - Jimbo',
  },
  data() {
    return {
      loading: true,
      invoice: {},
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$http.get(`${process.env.VUE_APP_API_URL}/invoice/pdf-infos/${this.$route.params.id}`)
        .then(res => {
          this.invoice = res.data
          console.log(res)
          this.loading = false
        })
        .catch(err => console.log(err))
        .finally()
    },
    getPerson() {
      switch (this.invoice.to) {
        case 'contact':
          return this.invoice.contact
        case 'bc':
          return this.invoice.businessContact

        default:
          return this.invoice.to
      }
    },
  },

}
</script>

<style>
.invoiceWrapper {
  margin: 25px
}
.a4{
  height: 100%;
}
</style>
